import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { graphql, useStaticQuery } from 'gatsby';
import * as React from 'react';
import { MarkupOptions } from '../../utilities';
import { LoadGA } from './tracking-google-analytics';
import { LoadSnapChat } from './tracking-snapchat';
import { LoadFB } from './tracking-facebook';
import { LoadTwitter } from './tracking-twitter';
import { LoadGTM } from './tracking-google-tag-manager';

import './cookie-consent-settings.scss';

export const CookieConsentSettings = () => {
  const cookieName = 'PrivacyConsent_1';

  const data = useStaticQuery(graphql`
    query CookieConsentUpdateQuery {
      settings: contentfulComponentCookies {
        settingsHeading
        settingsAnalyticsCookiesHeading
        settingsAnalyticsCookiesText {
          raw
        }
        settingsNecessaryCookiesHeading
        settingsNecessaryCookiesText {
          raw
        }
        settingsUpdateButtonText
      }
    }
  `);

  const [settings, updateSettings] = React.useState({
    analytics: false,
  });
  const [isSetup, changeIsSetup] = React.useState(false);

  const updateButton = React.useRef(null);

  const setup = () => {
    if (isSetup) {
      return;
    }

    changeIsSetup(true);

    if (document.cookie.indexOf(`${cookieName}=`) > -1) {
      try {
        const cookieSettings = JSON.parse(
          decodeURIComponent(
            document.cookie.replace(
              new RegExp(
                `(?:(?:^|.*;)\\s*${cookieName}\\s*\\=\\s*([^;]*).*$)|^.*$`
              ),
              '$1'
            )
          )
        );

        const newSettings = { ...settings };

        for (const key in newSettings) {
          if (
            newSettings.hasOwnProperty(key) &&
            newSettings[key] !== cookieSettings[key]
          ) {
            newSettings[key] = cookieSettings[key];
          }
        }

        updateSettings(newSettings);
      } catch (e) {
        document.cookie = `${cookieName}=; path=/; secure=true; expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
      }
    }
  };

  const handleChangeAnalytics = () => {
    updateSettings((prevState: any) => {
      const newSettings: any = {
        analytics: !settings.analytics,
      };

      return {
        ...prevState,
        ...newSettings,
      };
    });
  };

  const handleSave = () => {
    const tempDate = new Date();

    tempDate.setDate(tempDate.getDate() + 30);

    document.cookie = `${cookieName}=${JSON.stringify(
      settings
    )}; path=/; secure=true; SameSite=Strict; expires=${tempDate.toUTCString()}`;

    processCookieSettings();

    const prompt = document.getElementById('cookieConsent');

    if (prompt !== null) {
      const jQuery = require('jquery');

      jQuery(prompt).slideUp(600);
    }

    if (updateButton !== null && updateButton.current !== null) {
      const button: any = updateButton.current;
      const buttonText = button.innerText;

      button.innerText = 'Saving...';
      button.disabled = true;

      setTimeout(() => {
        button.innerText = 'Saved...';

        setTimeout(() => {
          button.innerText = buttonText;
          button.disabled = false;
        }, 800);
      }, 800);
    }
  };

  const processCookieSettings = () => {
    if (settings.analytics) {
      LoadGA();
      LoadSnapChat();
      LoadFB();
      LoadTwitter();
      LoadGTM();
    }
  };

  React.useEffect(() => {
    setup();
  }, [settings]);

  return (
    <div className="cookie-consent__settings" id="cookiesettings">
      <h2
        className="cookie-consent__settings__heading heading-highlighted"
        id="cookieConsentTitle"
      >
        {data.settings && data.settings.settingsHeading}
      </h2>
      <div className="cookie-consent__settings__text">
        {data.settings &&
          data.settings.settingsText &&
          data.settings.settingsText.raw &&
          documentToReactComponents(
            JSON.parse(data.settings.settingsText.raw),
            MarkupOptions
          )}
      </div>
      <div className="cookie-consent__settings__form">
        <div className="cookie-consent__settings__type">
          <h3 className="cookie-consent__settings__type__heading">
            {data.settings && data.settings.settingsNecessaryCookiesHeading}
          </h3>
          <div className="cookie-consent__settings__type__text">
            {data.settings &&
              data.settings.settingsNecessaryCookiesText &&
              data.settings.settingsNecessaryCookiesText.raw &&
              documentToReactComponents(
                JSON.parse(data.settings.settingsNecessaryCookiesText.raw),
                MarkupOptions
              )}
          </div>
        </div>
        <div className="cookie-consent__settings__type">
          <h3 className="cookie-consent__settings__type__heading">
            <label className="toggle">
              <input
                checked={settings.analytics}
                className="toggle__input"
                id="cookieConsentAnalytics"
                onChange={handleChangeAnalytics}
                type="checkbox"
              />
              <span className="toggle__label">
                {data.settings && data.settings.settingsAnalyticsCookiesHeading}
              </span>
              <span className="toggle__switch" />
            </label>
          </h3>
          <div className="cookie-consent__settings__type__text">
            {data.settings &&
              data.settings.settingsAnalyticsCookiesText &&
              data.settings.settingsAnalyticsCookiesText.raw &&
              documentToReactComponents(
                JSON.parse(data.settings.settingsAnalyticsCookiesText.raw),
                MarkupOptions
              )}
          </div>
        </div>
        <button
          className="btn cookie-consent__settings__button"
          onClick={handleSave}
          ref={updateButton}
          type="button"
        >
          {data.settings.settingsUpdateButtonText}
        </button>
      </div>
    </div>
  );
};
