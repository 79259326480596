import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { BLOCKS, INLINES, MARKS } from '@contentful/rich-text-types';
import * as React from 'react';
import { Component } from './components/component';

export const MarkupOptions = {
    renderMark: {
        [MARKS.BOLD]: (text: any) => {
            return <strong key={`${text}-key`}>{text}</strong>;
        },
        [MARKS.ITALIC]: (text: any) => {
            return (
                <span style={{ fontStyle: 'italic' }} key={`${text}-key`}>
                    {text}
                </span>
            );
        },
    },
    renderNode: {
        [INLINES.ASSET_HYPERLINK]: (node: any) => {
            if (
                typeof node !== 'undefined' &&
                node !== null &&
                typeof node.content !== 'undefined' &&
                node.content !== null &&
                node.content.length > 0 &&
                typeof node.data !== 'undefined' &&
                node.data !== null &&
                typeof node.data.target !== 'undefined' &&
                node.data.target !== null
            ) {
                for (const c of node.content) {
                    if (c.nodeType === 'text') {
                        let fileUrl = '';
                        
                        if (
                            typeof node.data.target.fields !== 'undefined' &&
                            node.data.target.fields !== null
                        ) {
                            for (const f in node.data.target.fields.file) {
                                if (
                                    node.data.target.fields.file[f] !== null && 
                                    node.data.target.fields.file[f].url !== null
                                ) {
                                    fileUrl = node.data.target.fields.file[f].url;
                                    break;
                                }
                            }
                        }

                        if (fileUrl === '') {
                            return c.value;
                        }
                        
                        return (
                            <a href={fileUrl}>
                                {c.value}
                            </a>
                        );
                    }
                }
            }
        },
        [INLINES.ENTRY_HYPERLINK]: (node: any) => {
            if (
                typeof node !== 'undefined' &&
                node !== null &&
                typeof node.content !== 'undefined' &&
                node.content !== null &&
                node.content.length > 0 &&
                typeof node.data !== 'undefined' &&
                node.data !== null &&
                typeof node.data.target !== 'undefined' &&
                node.data.target !== null
            ) {
                for (const c of node.content) {
                    if (c.nodeType === 'text') {
                        let url = null;
                        let clickHandler = null;

                        if (
                            typeof node.data.target.fields !== 'undefined' &&
                            node.data.target.fields !== null
                        ) {
                            if (
                                typeof node.data.target.fields.pageUrl !== 'undefined' &&
                                node.data.target.fields.pageUrl !== null
                            ) {
                                let pageUrl = null;

                                for (const f in node.data.target.fields.pageUrl) {
                                    if (node.data.target.fields.pageUrl[f] !== null) {
                                        pageUrl = node.data.target.fields.pageUrl[f];
                                        break;
                                    }
                                }

                                if (pageUrl !== null) {
                                    url = `/${pageUrl}/`;
                                }
                            }
                            else if (
                                typeof node.data.target.fields.componentId !== 'undefined' &&
                                node.data.target.fields.componentId !== null
                            ) {
                                let componentId = null;

                                for (const f in node.data.target.fields.componentId) {
                                    if (node.data.target.fields.componentId[f] !== null) {
                                        componentId = node.data.target.fields.componentId[f];
                                        break;
                                    }
                                }

                                if (componentId !== null) {
                                    url = `#${componentId}`;
                                    clickHandler = (evt) => {
                                        if (evt.target && evt.target.hash) {
                                            const el = document.getElementById(evt.target.hash.slice(1));

                                            if (el !== null) {
                                                evt.preventDefault();
                                                el.scrollIntoView({
                                                    behavior: 'smooth',
                                                });
                                            }
                                        }
                                    };
                                }
                            }
                        }

                        if (url === null) {
                            return c.value;
                        }

                        return (
                            <a href={url} onClick={clickHandler}>
                                {c.value}
                            </a>
                        );
                    }
                }
            }
        },
        [BLOCKS.EMBEDDED_ENTRY]: (node: any) => {
            if (
              typeof node !== 'undefined' &&
              node !== null &&
              typeof node.data !== 'undefined' &&
              node.data !== null &&
              typeof node.data.target !== 'undefined' &&
              node.data.target !== null &&
              typeof node.data.target.sys !== 'undefined' &&
              node.data.target.sys !== null &&
              typeof node.data.target.sys.contentType !== 'undefined' &&
              node.data.target.sys.contentType !== null &&
              typeof node.data.target.fields !== 'undefined' &&
              node.data.target.fields !== null
            ) {
                switch (node.data.target.sys.contentType.sys.id) {
                    case 'quote':
                        const fields = {};

                        for (const f in node.data.target.fields) {
                            const lang = Object.keys(node.data.target.fields[f])[0];
                            const value = node.data.target.fields[f][lang];

                            if (f === 'text') {
                                fields[f] = {
                                    text: value
                                }
                            }
                            else {
                                fields[f] = value;
                            }
                        }

                        return (
                            <Component
                                {...fields}
                                internal={{
                                    type: 'ContentfulComponentQuote'
                                }}
                            />
                        );
                }                
            }
        },     
        [BLOCKS.EMBEDDED_ASSET]: (node: any) => {
            if (
                typeof node !== 'undefined' &&
                node !== null &&
                typeof node.data !== 'undefined' &&
                node.data !== null &&
                typeof node.data.target !== 'undefined' &&
                node.data.target !== null &&
                typeof node.data.target.fields !== 'undefined' &&
                node.data.target.fields !== null &&
                typeof node.data.target.fields.description !== 'undefined' &&
                node.data.target.fields.description !== null
            ) {
                const { description, file } = node.data.target.fields;
                const descKey = Object.keys(description)[0] || null;
                const fileKey = Object.keys(file)[0] || null;

                if (descKey === null || fileKey === null) {
                    return null;
                }

                const mimeType = file[fileKey].contentType;
                const mimeGroup = mimeType.split('/')[0];

                switch (mimeGroup) {
                    case 'image':
                        return (
                            <picture>
                                <source
                                    media="(max-width: 767px)"
                                    srcSet={file[fileKey].url + '?w=736'}
                                />
                                <img
                                    alt={description[descKey]}
                                    className="page-content__image"
                                    loading="lazy"
                                    src={file[fileKey].url}
                                    height={file[fileKey].details.image.height}
                                    width={file[fileKey].details.image.width}
                                />
                            </picture>
                        );
                    case 'video':
                        return (
                            <figure className="page-content__figure">
                                <video
                                    autoPlay={false}
                                    className="page-content__video"
                                    controls={true}
                                >
                                    <source src={file[fileKey].url} type={mimeType} />
                                    <p>
                                        <a href={file[fileKey].url}>Click here to view the video</a>
                                    </p>
                                </video>
                                <figcaption className="page-content__figure__caption">
                                    {description[descKey]}
                                </figcaption>
                            </figure>
                        );
                }
            }
        },
    },
    renderText: (text: any) => {
        return text
            .split('\n')
            .reduce((children: any, textSegment: any, index: number) => {
                return [...children, index > 0 && <br key={index} />, textSegment];
            }, []);
    },
};
