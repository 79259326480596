import React from 'react';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { MarkupOptions } from '../../utilities';

import './introText.scss';

export const IntroText = (props) => {
  let columns = 0;

  if (props.introText && props.introText.raw) {
    columns += 1;
  }

  if (props.introText2 && props.introText2.raw) {
    columns += 1;
  }

  if (columns === 0) {
    return null;
  }

  return (
    <div className={`introText contain ${columns === 2 ? 'contain--2' : ''}`}>
      {props.introText && props.introText.raw && (
        <div className="introText__highlight contain__column">
          {documentToReactComponents(
            JSON.parse(props.introText.raw),
            MarkupOptions
          )}
        </div>
      )}

      {props.introText2 && props.introText2.raw && (
        <div className="introText__normal contain__column">
          {documentToReactComponents(
            JSON.parse(props.introText2.raw),
            MarkupOptions
          )}
        </div>
      )}
    </div>
  );
};
