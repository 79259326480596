import React from 'react';

import './quote.scss';

export const Quote = (props) => {
    if (
        typeof props.text === 'undefined' ||
        props.text === null ||
        props.text === ''
    ) {
        return null;
    }

    return (
        <div
            className={`quote ${props.hasBackgroundColour ? 'quote--bg' : ''}`}
            id={props.componentId}
        >
            <blockquote className="contain quote__content">
                {props.text && (
                    <div className="quote__content__text">
                        {props.text.text}
                    </div>
                )}
                {props.citing && (
                    <footer className="quote__content__cite">
                        {props.citing}
                    </footer>
                )}
            </blockquote>
        </div>
    );
};
