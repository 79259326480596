import React from 'react';

import './videos.scss';

export const Videos = (props) => {
    if (
        typeof props.items === 'undefined' ||
        props.items === null ||
        props.items.length === 0
    ) {
        return null;
    }

    return (
        <div className="videos" id={props.componentId}>
            {props.items.map((item, itemIndex) => (
                <div className="videos__item" key={itemIndex}>
                    <div className="videos__item__video">
                        <iframe width="465" height="262" src={item.youTubeEmbedUrl} frameBorder="0" allow="accelerometer; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                    </div>
                    <div className="videos__item__text">{item.title}</div>
                </div>
            ))}
        </div>
    );
};
