import React from 'react';

import './partners.scss';

export const Partners = (props) => {
  return (
    <div className="partners contain contain--2">
      <div className="partners__item__image contain__column">
        {props.image && (
          <img alt={props.image.description} src={`${props.image.file.url}`} />
        )}
      </div>
      <div className="partners__item__content contain__column">
        {props.heading && <h3>{props.heading}</h3>}
        {props.description?.description && (
          <div>{props.description.description}</div>
        )}
        {props.buttonURL && props.buttonText && (
          <div className="partners__item__link">
            <a
              className="partners__item__button"
              href={props.buttonURL}
              target="_blank"
            >
              {props.buttonText}
            </a>
          </div>
        )}
      </div>
    </div>
  );
};
