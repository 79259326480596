import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import './films.scss';

export const Films = (props) => {
    const data = useStaticQuery(graphql`
        query FilmsQuery {
            findACinema: contentfulPage (pageType: {eq: "find a cinema"}) {
                pageUrl
            }
        }
    `);

    const overlayIdPrefix = `filmsOverlay-${props.componentId}-`;
    const [items, setItems] = React.useState(props.items || []);
    const scroller = React.useRef(null);
    const [scrollPosition, setScrollPosition] = React.useState(0);
    const itemWidth = 296;
    const itemMargin = 8;

    let maxWidth = items.length > 0 ? ((items.length - 1) * itemWidth) - itemMargin : 0;
    
    if (
        typeof document !== 'undefined' &&
        document !== null &&
        maxWidth > document.documentElement.clientWidth
    ) {
        maxWidth = maxWidth + itemWidth + (itemMargin * 2) - document.documentElement.clientWidth;
    }

    let touchXDown = null;
    let touchYDown = null;
    
    const toggleOverlay = (index) => {
        const overlay = document && document.getElementById(`${overlayIdPrefix}${index}`);

        if (typeof overlay !== 'undefined' && overlay !== null) {
            if (overlay.style.opacity === '1') {
                overlay.addEventListener('transitionend', () => {
                    overlay.style.display = 'none';
                }, {once: true});
                overlay.style.opacity = '0';
            }
            else {
                overlay.style.opacity = '0';
                overlay.style.display = 'block';
                
                setTimeout(() => {
                    overlay.style.opacity = '1';
                }, 1);
            }
        }
    };

    const scrollItems = (dir) => {
        let newPosition = scrollPosition;

        if (dir === 1) {
            if (newPosition === 0) {
                newPosition += itemWidth - itemMargin;
            }
            else {
                newPosition += itemWidth;
            }
        }
        else {
            newPosition -= itemWidth;            
        }

        if (newPosition < 0) {
            newPosition = 0;
        }

        if (newPosition > maxWidth) {
            newPosition = maxWidth;
        }

        setScrollPosition(newPosition);
    };

    const handleTouchStart = (evt) => {
        touchXDown = evt.touches[0].clientX;
        touchYDown = evt.touches[0].clientY;
    };

    const handleTouchMove = (evt) => {
        if (
            document.documentElement.clientWidth > 767 ||
            touchXDown === null ||
            touchYDown === null
        ) {
            return;
        }

        const touchXUp = evt.touches[0].clientX;
        const touchYUp = evt.touches[0].clientY;
        const touchXDiff = touchXDown - touchXUp;
        const touchYDiff = touchYDown - touchYUp;

        if (Math.abs(touchXDiff) > Math.abs(touchYDiff)) {
            if (touchXDiff > 0) {
                // left
                scrollItems(1);
            }
            else {
                // right
                scrollItems(-1);
            }
        }
        else {
            if (touchYDiff > 0) {
                // up
            }
            else {
                // down
            }
        }
    };

    const handleTouchEnd = () => {
        touchXDown = null;
        touchYDown = null;
    };

    React.useEffect(() => {
        if (scroller && scroller.current) {
            scroller.current.style.transform = `translateX(-${scrollPosition}px)`;
        }
    }, [scrollPosition]);

    return (
        <React.Fragment>
            {props.componentHeading && (
                <div className="contain films__heading">
                    <div className="contain__column">
                        <h2 className="films__heading__text">{props.componentHeading}</h2>
                    </div>
                </div>
            )}
            <div className="films" id={props.componentId}>
                <button
                    className={`films__prevbtn ${scrollPosition === 0 ? 'is-disabled' : ''}`}
                    onClick={() => scrollItems(-1)}
                    type="button"
                />
                <button
                    className={`films__nextbtn ${scrollPosition === maxWidth ? 'is-disabled' : ''}`}
                    onClick={() => scrollItems(1)}
                    type="button"
                />
                <div
                    className="films__scroll"
                    onTouchStart={handleTouchStart}
                    onTouchMove={handleTouchMove}
                    onTouchEnd={handleTouchEnd}
                    ref={scroller}
                >
                    {items.map((item, index) => (
                        <FilmItem
                            {...item}
                            index={index}
                            key={index}
                            toggleOverlay={toggleOverlay}
                        />
                    ))}
                </div>
                {items.map((item, index) => (
                    <FilmOverlay
                        {...item}
                        index={index}
                        key={index}
                        toggleOverlay={toggleOverlay}
                        overlayIdPrefix={overlayIdPrefix}
                        findACinema={data.findACinema}
                    />
                ))}
            </div>
        </React.Fragment>
    );
};

const FilmItem = (props) => {
    return (
        <div className="films__item" ref={props.ref}>
            <button
                aria-label={`find out more about ${props.name}`}
                className="films__item__btn"
                onClick={() => props.toggleOverlay(props.index)}
                type="button"
            />
            {props.poster && (
                <div className="films__item__image">
                    <img
                        alt={props.poster.description}
                        src={`${props.poster.file.url}?w=233`}
                    />
                </div>
            )}
            {props.name && (
                <div className="films__item__title">
                    {props.name}
                </div>
            )}
            {props.classification && (
                <div className="films__item__info">
                    {props.classification}
                </div>
            )}            
        </div>
    );
};

const FilmOverlay = (props) => {    
    const [expandSynopsis, setExpandSynopsis] = React.useState(false);

    const toggleSynopsis = () => {
        setExpandSynopsis(!expandSynopsis);
    };

    const showSynopsis = (text) => {
        if (expandSynopsis) {
            return (
                <p>
                    {text}
                    <button
                        aria-label={`show less of the ${props.name} synopsis`}
                        className="showLessMore"
                        onClick={toggleSynopsis}
                        type="button"
                    >
                        show less
                    </button>
                </p>
            );
        }
        else {
            if (text.length < 181) {
                return (<p>{text}</p>);
            }

            return (
                <p>
                    {`${text.substring(0, 180)}...`}
                    <button
                        aria-label={`show more of the ${props.name} synopsis`}
                        className="showLessMore"
                        onClick={toggleSynopsis}
                        type="button"
                    >
                        show more
                    </button>
                </p>
            );
        }
    };

    if (props.trailer && props.trailer.toLowerCase().indexOf('www.youtube.com') > -1) {
        props.trailer = props.trailer.replace(/www.youtube.com/i, 'www.youtube-nocookie.com');
    }

    return (
        <div
            className="films__item__overlay"
            id={`${props.overlayIdPrefix}${props.index}`}
            style={{
                display: 'none',
                opacity: '0'
            }}
        >
            <button
                aria-label="close overlay"
                className="films__item__overlay__btn"
                onClick={() => props.toggleOverlay(props.index)}
                type="button"
            />
            <div className="films__item__overlay__content">
                <div className="films__item__overlay__content__inner">
                    <button
                        aria-label="close overlay"
                        className="films__item__overlay__close"
                        onClick={() => props.toggleOverlay(props.index)}
                        type="button"
                    />
                    <div className="films__item__overlay__title">
                        {props.name}
                    </div>
                    {props.classification && (
                        <div className="films__item__overlay__info">
                            {props.classification}
                        </div>
                    )}
                    {props.trailer && (
                        <div className="films__item__overlay__trailer">
                            <iframe width="784" height="441" src={props.trailer} frameBorder="0" allow="accelerometer; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                        </div>
                    )}
                    <div className="films__item__overlay__columns">
                        <div className="films__item__overlay__columns__main">
                            {props.synopsis && showSynopsis(props.synopsis.synopsis)}
                            {props.linkUrl && props.linkText && (
                                <p>
                                    <a
                                        aria-label={`${props.linkText} (opens in a new window)`}
                                        href={props.linkUrl}
                                        rel="noopener noreferrer"
                                        target="_blank"
                                    >
                                        {props.linkText}
                                    </a>
                                </p>
                            )}
                        </div>
                        <div className="films__item__overlay__columns__aside">
                            <a href={`/${props.findACinema.pageUrl}/`}>
                                Find a cinema
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
