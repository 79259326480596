import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { MarkupOptions } from '../../utilities';

import iconFacebook from '../../images/social-icon-facebook.png';
import iconTwitter from '../../images/social-icon-twitter.png';
import iconInstagram from '../../images/social-icon-instagram.png';
import iconSnapchat from '../../images/social-icon-snapchat.png';

import './footer.scss';

export const Footer = (props) => {
  const data = useStaticQuery(graphql`
    query footerQuery2 {
      footer: contentfulFooter {
        # logo {
        #   description
        #   file {
        #     url
        #   }
        # }
        menuItems {
          #   ... on ContentfulContactUs {
          #     name
          #     pageUrl
          #     internal {
          #       type
          #     }
          #   }
          ... on ContentfulPage {
            name
            pageUrl
            internal {
              type
            }
          }
        }
        # text {
        #   json
        # }
        partnerIcons {
          description
          file {
            url
          }
        }
      }
    }
  `);
  let hidePartners = false;
  if(props.content.pageBackground) {
    hidePartners = true;
  } 
  return (
    <footer className="page-footer">
      {/* <div className="contain page-footer__content">
        <div className="page-footer__content__image">
          {data.footer.logo && (
            <img
              alt={data.footer.logo.description}
              src={`${data.footer.logo.file.url}?w=124`}
            />
          )}
        </div>
        <div className="page-footer__content__text">
          {documentToReactComponents(data.footer.text.json, MarkupOptions)}
        </div>
      </div> */}

      {!hidePartners && <div className="page-footer__partners">
        <div className="contain">
          <div className="contain__column">
            {data.footer.partnerIcons &&
              data.footer.partnerIcons.map((item, itemIndex) => (
                <img
                  alt={item.description}
                  key={itemIndex}
                  src={`${item.file.url}?w=135`}
                />
              ))}
          </div>
        </div>
      </div>}
      <div className="page-footer__divider" />
      <nav
        aria-label="secondary navigation"
        className="contain page-footer__nav"
      >
        <ul>
          {data.footer.menuItems &&
            data.footer.menuItems.map((item, itemIndex) => {
              const text = item.menuText || item.name;
              let url = `/${item.pageUrl}/`;

              if (item.internal.type === 'ContentfulContactUs') {
                url = `mailto:${item.pageUrl}`;
              }

              return (
                <li key={itemIndex}>
                  <a href={url}>{text}</a>
                </li>
              );
            })}
        </ul>
      </nav>
    </footer>
  );
};
