import React from 'react';
import * as jQuery from 'jquery';
import { DotProGraphQuery } from './DotProGraphQuery';
import { GraphQuery } from './GraphQuery';
import { CinemaFinderLoading } from './cinema-finder-loading';

import './cinema-finder-item.scss';

export const CinemaFinderItem = (props) => {
  const button = React.useRef(null);
  const content = React.useRef(null);
  const [isOpen, setIsOpen] = React.useState(false);
  const [films, setFilms] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);
  const [hasLoaded, setHasLoaded] = React.useState(false);

  const tempDate = new Date();
  tempDate.setDate(tempDate.getDate() + 7);

  // const tempDateFormatted = tempDate.toLocaleDateString('en-GB', {
  //   day: 'numeric',
  //   month: 'long',
  //   weekday: 'long',
  //   year: 'numeric',
  // });

  const getBestTitle = (movie) => {
    return movie.en_GB?.title || movie.fallback?.title || movie.title;
  };
  const getFilms = () => {
    if (hasLoaded || isLoading) {
      return;
    }

    setIsLoading(true);

    const query = `
      query {
        external {
          showtimes {
            byTheater (
              theater: "${atob(props.node.id).split(':')[1]}",
              to: "${tempDate.toISOString().split('T')[0]}"
            ) {
              movie {
                title
                en_GB: localeData(locale: "en_GB") {
                  title
                }
                fallback: localeData(locale: "en_US") {
                  title
                }
                mainRelease(country: "uk") {
                  certificate
                }
              }
              showtimes {
                startsAt
                tags
                data {
                  ticketing {
                    urls
                  }
                }
              }
            }
          }
        }
      }
    `;

    
  };

  const handleClick = () => {
    setIsOpen(!isOpen);
    getFilms();
  };

  return (
    <div className="cinema-finder__results__item">
      <a
        className={'cinema-finder__results__item__header'}
        href={props.node.url}
        target="_blank"
      >
        <span className="cinema-finder__results__item__header__main">
          {props.node.name}
        </span>
        <span className="cinema-finder__results__item__header__extra">
          {`${props.node.location.address}, ${props.node.location.city} ${props.node.location.zip}`}
        </span>
       
      </a>
      
    </div>
  );
};

const formatShowtimes = (showtimes) => {
  const showtimesData = [];

  let dateCounter = -1;

  showtimes.forEach((showtime) => {
    const tempDate = showtime.startsAt.split(' ')[0];
    const tempDateSplit = tempDate.split('-');
    const d = new Date(
      parseInt(tempDateSplit[0], 10),
      parseInt(tempDateSplit[1], 10) - 1,
      parseInt(tempDateSplit[2], 10)
    );
    const tempDateFormatted = d.toLocaleDateString('en-GB', {
      day: 'numeric',
      month: 'long',
      weekday: 'long',
      year: 'numeric',
    });

    const tempTime = showtime.startsAt.split(' ')[1].slice(0, -3);
    const tempUrl =
      showtime.data.ticketing.length > 0
        ? showtime.data.ticketing[0].urls[0]
        : '';

    if (dateCounter === -1 || showtimesData[dateCounter].date !== tempDate) {
      dateCounter += 1;
      showtimesData[dateCounter] = {
        date: tempDate,
        dateFormatted: tempDateFormatted,
        times: [
          {
            time: tempTime,
            url: tempUrl,
            tags: showtime.tags,
          },
        ],
      };
    } else {
      showtimesData[dateCounter].times.push({
        time: tempTime,
        url: tempUrl,
        tags: showtime.tags,
      });
    }
  });

  return showtimesData;
};
