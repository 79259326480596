import React from 'react';

import './icon.scss';

export const Icon = (props) => {
    if (
        typeof props.items === 'undefined' ||
        props.items === null ||
        props.items.length === 0
    ) {
        return null;
    }

    return (
        <div className="icons" id={props.componentId}>
            {props.title && (
                <div className="icons__title">
                    <div className="contain">
                        <div className="contain__column">
                            <h2 className="icons__title__text">{props.title}</h2>
                        </div>
                    </div>
                </div>
            )}
            <div className="icons__items">
                {props.items.map((item, itemIndex) => (
                    <div className="icons__item" key={itemIndex}>
                        <div className="icons__item__image">
                            {item.image && (
                                <img
                                    alt={item.image.description}
                                    src={`${item.image.file.url}?w=150`}
                                />
                            )}
                        </div>
                        <div className="icons__item__content">
                            {item.title && (
                                <div className="icons__item__content__title">
                                    {item.title}
                                </div>
                            )}
                            {item.text && (
                                <div className="icons__item__content__text">
                                    {item.text.text}
                                </div>
                            )}
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};
