export const GraphQuerySettings = {
  url: 'https://graph.allocine.fr/v1/ukca/',
  key: `eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJpYXQiOjE3MTg3MjExNzEsImV4cCI6MTc4MTU2MDc5OSwidXNlcm5hbWUiOiJhbm9ueW1vdXMiLCJhcHBsaWNhdGlvbl9uYW1lIjoidWtjYSIsInV1aWQiOiI0MGEzMWI0Ni0zNDQyLTQ4ZWQtYWQ5ZS05MmMwOWMxYzFhMmIiLCJzY29wZSI6bnVsbH0.DyMsQ61I9POaIPuLvRfhQt_3t7HKVUB0fyW9fsyIOQsWgzYKnmggyxHnJ0MSsxAi2V0GJFUU6K6YlRtQEANY3YtJp6bOtgvI1L98puL81TamwsianUTI5Ye4piig8C65lCYDHbD1a4YO-rZ7UL5Qwh29shqc-ScqiURC-FPxAejEmFUjDDv2tbWOy0UQVIAIz7QpWbOYdclQlBfHX62Dhc73RRL1qt1YCj668Qe5KTGh4HcYWC6L_x0CbdFtl7U7zgWN1spxMT2-22KPPNbKnri1PwPdZuazG77qJ3r_iDQJSvrqi2fM1d7Hf3qfTOH3iZcoFh-0vVxnZv3l0EDvILRykyPDvAQrL2uDDY8yeR7g-FsYXBUhJNxvZwvZL3oF04H8TZa_zEVjXCIWLVNaS5mQJ6h7YI72YNLWffKWLQs_iHggeEukJKREGVaYMnyjEC3NaJcyT5r-jnUcmIbusmHW7xegIYDjgWQ2FXT-LvVp5Y_DO5KLUkNPnDMFP86drQD4YrJ6qDcftxK2bqvvtaO8477v5M5C9dXAzT97CDxKTe9-qU4nRIfdF4kYAcch5z4MiJZXh0gl36kEETOX0Hh17Il5Dh7Xm-GSlkHVGHSiYR4qCRC_5F1EF7TFPWjB7xru-V8PVBgrvsDbS971QqNXmDHPVl_3aHnrBz0tawc`,
  id: 'Q29tcGFueToxMDAwMTA4MjI0',
  limit: 100,
  radius: 20000,
};

export const GraphQuery = (query, resultKey) => {
  return fetch(GraphQuerySettings.url, {
    body: JSON.stringify({ query }),
    headers: {
      Authorization: `Bearer ${GraphQuerySettings.key}`,
      'Content-Type': 'application/json',
    },
    method: 'POST',
  })
    .then((r) => r.json())
    .then((r) => {
      if (
        typeof r === 'undefined' ||
        r === null ||
        typeof r.data === 'undefined' ||
        r.data === null ||
        typeof r.data[resultKey] === 'undefined' ||
        r.data[resultKey] === null
      ) {
        throw Error(r);
      }
      return r;
    });
};
