import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { graphql, useStaticQuery } from 'gatsby';
import * as React from 'react';
import { MarkupOptions } from '../../utilities';
import { LoadGA } from './tracking-google-analytics';
import { LoadSnapChat } from './tracking-snapchat';
import { LoadFB } from './tracking-facebook';
import { LoadTwitter } from './tracking-twitter';
import { LoadGTM } from './tracking-google-tag-manager';

import './cookie-consent-prompt.scss';

export const CookieConsentPrompt = () => {
  const cookieName = 'PrivacyConsent_1';
  const cookieNameOld = 'CookieConsent';

  const data = useStaticQuery(graphql`
    query CookieConsentPromptQuery {
      prompt: contentfulComponentCookies {
        promptButtonText
        promptHeading
        promptManageButtonText
        promptManagePage {
          pageUrl
        }
        promptText {
          raw
        }
      }
    }
  `);

  const [settings, updateSettings] = React.useState({
    analytics: false,
  });
  const [isSetup, changeIsSetup] = React.useState(false);
  const [hasCookie, updateHasCookie] = React.useState(true);

  const setup = () => {
    if (isSetup) {
      return;
    }

    changeIsSetup(true);

    // delete old cookie
    document.cookie = `${cookieNameOld}=; path=/; secure=true; expires=Thu, 01 Jan 1970 00:00:01 GMT;`;

    if (document.cookie.indexOf(`${cookieName}=`) > -1) {
      try {
        const cookieSettings = JSON.parse(
          decodeURIComponent(
            document.cookie.replace(
              new RegExp(
                `(?:(?:^|.*;)\\s*${cookieName}\\s*\\=\\s*([^;]*).*$)|^.*$`
              ),
              '$1'
            )
          )
        );

        const newSettings = { ...settings };

        for (const key in newSettings) {
          if (
            newSettings.hasOwnProperty(key) &&
            newSettings[key] !== cookieSettings[key]
          ) {
            newSettings[key] = cookieSettings[key];
          }
        }

        updateSettings(newSettings);
      } catch (e) {
        document.cookie = `${cookieName}=; path=/; secure=true; expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
      }
    } else {
      updateHasCookie(false);
    }
  };

  const handleSave = () => {
    const tempDate = new Date();

    tempDate.setDate(tempDate.getDate() + 30);

    const newSettings: any = { ...settings };

    for (const key in newSettings) {
      if (newSettings.hasOwnProperty(key)) {
        newSettings[key] = true;
      }
    }

    updateSettings(newSettings);

    document.cookie = `${cookieName}=${JSON.stringify(
      newSettings
    )}; path=/; secure=true; SameSite=Strict; expires=${tempDate.toUTCString()}`;

    const prompt = document.getElementById('cookieConsent');

    if (prompt !== null) {
      const jQuery = require('jquery');

      jQuery(prompt).slideUp(600);
    }

    const toggleAnalytics: any = document.getElementById(
      'cookieConsentAnalytics'
    );

    if (toggleAnalytics !== null) {
      toggleAnalytics.checked = true;
    }
  };

  const processCookieSettings = () => {
    if (settings.analytics) {
      LoadGA();
      LoadSnapChat();
      LoadFB();
      LoadTwitter();
      LoadGTM();
    }
  };

  React.useEffect(() => {
    setup();
  });

  React.useEffect(() => {
    processCookieSettings();
  }, [settings]);

  return (
    <React.Fragment>
      {hasCookie === false && (
        <div
          aria-describedby="cookieConsentText"
          aria-labelledby="cookieConsentTitle"
          className="cookie-consent"
          id="cookieConsent"
          role="alertdialog"
        >
          <div className="contain">
            <div className="contain__column">
              <div className="cookie-consent__intro">
                <h2
                  className="cookie-consent__intro__heading"
                  id="cookieConsentTitle"
                >
                  {data.prompt && data.prompt.promptHeading}
                </h2>
                <div
                  className="cookie-consent__intro__text"
                  id="cookieConsentText"
                >
                  {data.prompt &&
                    data.prompt.promptText &&
                    data.prompt.promptText.raw &&
                    documentToReactComponents(
                      JSON.parse(data.prompt.promptText.raw),
                      MarkupOptions
                    )}
                </div>
              </div>
              <div className="cookie-consent__ctas">
                <button
                  className="btn cookie-consent__button"
                  onClick={handleSave}
                  type="button"
                >
                  {data.prompt.promptButtonText}
                </button>
                {data.prompt.promptManagePage && (
                  <a
                    className="cookie-consent__manage"
                    href={`/${data.prompt.promptManagePage.pageUrl}/#cookies-settings`}
                  >
                    {data.prompt.promptManageButtonText}
                  </a>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};
