import React from 'react';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { MarkupOptions } from '../../utilities';
import { CinemaFinderItem } from './cinema-finder-item';
import { CinemaFinderLoading } from './cinema-finder-loading';
import { CinemaFinderSearch } from './cinema-finder-search';

import './cinema-finder.scss';

export const CinemaFinder = (props) => {
  const [isLoading, setIsLoading] = React.useState(false);
  const [results, setResults] = React.useState([]);
  const [noResults, setNoResults] = React.useState(false);

  return (
    <div className="cinema-finder">
      <div className="contain">
        <div className="contain__column">
          {props.title && (
            <h2 className="cinema-finder__heading">{props.title}</h2>
          )}
          <CinemaFinderSearch
            {...props}
            isLoading={isLoading}
            setIsLoading={setIsLoading}
            setResults={setResults}
            setNoResults={setNoResults}
          />
          {isLoading && <CinemaFinderLoading />}
          {isLoading === false && results.length > 0 && (
            <div className="cinema-finder__results">
              {results.map((item, index) => (
                <CinemaFinderItem {...(item as object)} key={index} />
              ))}
            </div>
          )}
          {noResults && props.noResultsMessage && (
            <div className="cinema-finder__message">
              {props.noResultsMessage}
            </div>
          )}
          {props.text && (
            <div className="cinema-finder__text">
              {documentToReactComponents(
                JSON.parse(props.text.raw),
                MarkupOptions
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
