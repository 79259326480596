import React from 'react';

import './mainTitle.scss';

export const MainTitle = (props) => {
    return (
        <React.Fragment>
            {(props.title || props.subTitle) && (
                <div className="mainTitle contain">
                    <div className="contain__column">
                        {props.title && (
                            <h1 className="mainTitle__title">
                                {props.title}
                            </h1>
                        )}
                        {props.subTitle && (
                            <h2 className="mainTitle__subtitle">
                                {props.subTitle}
                            </h2>
                        )}
                    </div>
                </div>
            )}
        </React.Fragment>
    );
};
