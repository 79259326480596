import React from 'react';

import { graphql } from 'gatsby';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { MarkupOptions } from '../utilities';
import { Layout } from './layout';
import { Component } from '../components/component';
import { IntroText } from '../components/introText/introText';
import { OutroText } from '../components/outroText/outroText';
import { MainTitle } from '../components/mainTitle/mainTitle';

export default function Page(props) {
  return (
    <Layout content={props.data.content}>
      {/*props.data.content.buttonLink && props.data.content.buttonText && (
        <div className="page-content__intro-button">
          <a href={`/${props.data.content.buttonLink.pageUrl}/`}>
            {props.data.content.buttonText}
          </a>
        </div>
      )*/}
      <MainTitle
        title={props.data.content.title}
        subTitle={props.data.content.subTitle}
      />

      <IntroText
        introText={props.data.content.introText}
        introText2={props.data.content.introText2}
      />

      {props.data.content.text && (
        <div className="contain page-content__text">
          <div className="contain__column">
            {documentToReactComponents(
              JSON.parse(props.data.content.text.raw),
              MarkupOptions
            )}
          </div>
        </div>
      )}

      {props.data.content.components && (
        <div className="page-components">
          {props.data.content.components.map((item, itemIndex) => (
            <Component key={itemIndex} {...item} />
          ))}
        </div>
      )}

      {props.data.content.outroText && (
        <OutroText outroText={props.data.content.outroText} />
      )}
    </Layout>
  );
}

export const pageQuery = graphql`
  query PageQuery($pageUrl: String!) {
    content: contentfulPage(pageUrl: { eq: $pageUrl }) {
      seoTitle
      seoDescription
      seoDescriptionText
      name
      title
      subTitle
      pageType
      pageUrl
      introText {
        raw
      }
      introText2 {
        raw
      }
      text {
        raw
      }
      outroText
      # buttonText
      #buttonLink {
      # pageUrl
      #}
      #pageBackground {
      # description
      #file {
      #  url
      #}
      #}
      components {
        ... on ContentfulComponentCinemas {
          componentId
          internal {
            type
          }
          title
          locationFieldLabel
          searchButtonLabel
          noResultsMessage
          geolocationErrorMessage
          resultsText
        }
        ... on ContentfulComponentCookies {
          componentId
          internal {
            type
          }
        }
        ... on ContentfulComponentFilms {
          componentId
          componentHeading
          internal {
            type
          }
          items {
            classification
            name
            poster {
              description
              file {
                url
              }
            }
            synopsis {
              synopsis
            }
            trailer
            linkText
            linkUrl
          }
        }
        ... on ContentfulComponentIcons {
          componentId
          internal {
            type
          }
          items {
            image {
              description
              file {
                url
              }
            }

            title
            text {
              text
            }
          }
          title
        }
        ... on ContentfulComponentQuote {
          componentId
          internal {
            type
          }
          text {
            text
          }
          citing
          hasBackgroundColour
        }
        ... on ContentfulComponentVideos {
          componentId
          internal {
            type
          }
          items {
            title
            youTubeEmbedUrl
          }
        }
        ... on ContentfulComponentPartners {
          internal {
            type
          }
          buttonText
          buttonURL
          heading
          description {
            description
          }
          image {
            title
            file {
              url
            }
          }
        }
      }
    }
  }
`;
