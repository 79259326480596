import React from 'react';
import { CinemaFinder } from './cinema-finder/cinema-finder';
import { CookieConsentSettings } from './cookie-consent/cookie-consent';
import { Films } from './films/films';
import { Icon } from './icon/icon';
import { Quote } from './quote/quote';
import { Videos } from './videos/videos';
import { Partners } from './partners/partners';

export const Component = (props) => {
  if (props.internal && props.internal.type) {
    switch (props.internal.type) {
      case 'ContentfulComponentCinemas':
        return <CinemaFinder {...props} />;
      case 'ContentfulComponentCookies':
        return <CookieConsentSettings />;
      case 'ContentfulComponentFilms':
        return <Films {...props} />;
      case 'ContentfulComponentIcons':
        return <Icon {...props} />;
      case 'ContentfulComponentQuote':
        return <Quote {...props} />;
      case 'ContentfulComponentVideos':
        return <Videos {...props} />;
      case 'ContentfulComponentPartners':
        return <Partners {...props} />;
    }
  }

  return null;
};
