import React from 'react';

import './outroText.scss';

export const OutroText = (props) => {
    
    return (
        <div className={`outroText contain`}>
            {props.outroText && (
                <div className="outroText__highlight contain__column">
                    {props.outroText}
                </div>
            )}
        </div>
    );
};
