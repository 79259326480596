import React from 'react';
import { Helmet } from 'react-helmet';
import { graphql, useStaticQuery } from 'gatsby';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { MarkupOptions } from '../utilities';
import { CookieConsentPrompt } from '../components/cookie-consent/cookie-consent';
import { Header } from '../components/header/header';
import { Footer } from '../components/footer-2/footer';

import mainLogo from '../images/NCD-Social.jpg';

import './layout.scss';

export const Layout = (props) => {
  let pageUrl = '';

  if (props.content.pageUrl && props.content.pageUrl !== 'home') {
    pageUrl = props.content.pageUrl + '/';
  }

  let hasBackground = false;
  if (props.content.pageBackground) {
    hasBackground = true;
  }
  let inlineStyle = {};
  if (hasBackground) {
    inlineStyle = {
      backgroundImage: `url(${props.content.pageBackground.file.url})`,
    };
  }
  React.useEffect(() => {
    window.scrollTo(0, 0);
  });

  return (
    <React.Fragment>
      <Helmet defer={false}>
        <html lang="en" />

        {props.content.seoTitle && <title>{props.content.seoTitle}</title>}

        {props.content.seoDescriptionText && (
          <meta name="description" content={props.content.seoDescriptionText} />
        )}

        <meta property="og:site_name" content="National Cinema Day" />
        <meta
          property="og:image"
          content={`https://www.nationalcinemaday.uk${mainLogo}`}
        />
        <meta property="og:image:width" content="840px" />
        <meta property="og:image:height" content="440px" />
        <meta
          property="og:url"
          content={`https://www.nationalcinemaday.uk/${pageUrl}`}
        />

        {props.content.seoTitle && (
          <meta property="og:title" content={props.content.seoTitle} />
        )}

        {props.content.seoDescriptionText && (
          <meta
            property="og:description"
            content={props.content.seoDescriptionText}
          />
        )}

        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@LoveCinemaUK" />
        <meta name="twitter:creator" content="@LoveCinemaUK" />
        <meta
          name="twitter:image:src"
          content={`https://www.nationalcinemaday.uk${mainLogo}`}
        />
        <meta name="twitter:image:alt" content="National Cinema Day logo" />

        {props.content.seoTitle && (
          <meta name="twitter:title" content={props.content.seoTitle} />
        )}

        {props.content.seoDescriptionText && (
          <meta
            name="twitter:description"
            content={props.content.seoDescriptionText}
          />
        )}

        <link
          href="https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,500;0,800;1,500;1,800&display=swap"
          rel="stylesheet"
        />

        <meta
          name="google-site-verification"
          content="aqbFj6l9sa1DNjDaY15mk5WjfciJ6gyOMEqf_TMlBCU"
        />
      </Helmet>
      <noscript>
        <iframe
          src="https://www.googletagmanager.com/ns.html?id=GTM-NS5DMWJ"
          height="0"
          width="0"
          style={{ display: 'none', visibility: 'hidden' }}
        ></iframe>
      </noscript>
      <a href="#content" className="skip-link">
        Skip to content
      </a>
      <div className={`page ${pageUrl ? '' : 'page__home'}`}>
        <CookieConsentPrompt />

        <Header {...props} />
        <main
          id="content"
          className={`page-content ${
            hasBackground ? 'page-content__background' : ''
          }`}
          style={inlineStyle}
        >
          {props.children}
        </main>
        <Footer {...props} />
      </div>
    </React.Fragment>
  );
};
