import React from 'react';
import { GraphQuery, GraphQuerySettings } from './GraphQuery';

import './cinema-finder-search.scss';

const tempDate = new Date();
tempDate.setDate(tempDate.getDate() + 7);

export const CinemaFinderSearch = (props) => {
  const useMyLocationLabel = 'My location';
  const [hasGeoLocation, setHasGeoLocation] = React.useState(null);
  const [checkGeoLocation, setCheckGeoLocation] = React.useState(false);
  const [hasGeoLocationError, setHasGeoLocationError] = React.useState(false);
  const [gotLocations, setGotLocations] = React.useState(false);
  const [cities, setCities] = React.useState([]);
  const [searchValue, setSearchValue] = React.useState('');
  const [isDatalist, setIsDatalist] = React.useState(false);
  const [resultName, setResultName] = React.useState('');
  const getLocations = () => {
    if (gotLocations) {
      return;
    }

    setGotLocations(true);

    const tempCinemas = [];

    const makeCall = (endCursor) => {
      let after = '';

      if (typeof endCursor !== 'undefined' && endCursor !== null) {
        after = `after: "${endCursor}",`;
      }

      const query = `
                query {
                    theaterList(
                        ${after}
                        countries: [UNITED_KINGDOM],
                        first: ${GraphQuerySettings.limit},
                        order: [ALPHABETICAL]
                    ) {
                        pageInfo {
                            hasNextPage
                            endCursor
                        }
                        edges {
                            node {
                                name
                                location {
                                    city
                                }
                                showtimesDates (
                                    country: [UNITED_KINGDOM]
                                    to: "${
                                      tempDate.toISOString().split('T')[0]
                                    }"
                                )
                            }
                        }
                    }
                }
            `;

      GraphQuery(query, 'theaterList').then((r) => {
        Array.prototype.push.apply(tempCinemas, r.data.theaterList.edges);

        if (r.data.theaterList.pageInfo.hasNextPage) {
          makeCall(r.data.theaterList.pageInfo.endCursor);
        } else {
          completeCall();
        }
      });
    };

    const completeCall = () => {
      const tempCitiesObj: any = {};
      const tempCities: any = [];

      tempCinemas
        .filter((item) => {
          return item.node.showtimesDates.length > 0;
        })
        .forEach((cinema: any, index: number) => {
          if (typeof cinema.node === 'undefined' || cinema.node === null) {
            return true;
          }

          if (
            typeof cinema.node.location !== 'undefined' &&
            cinema.node.location !== null &&
            typeof cinema.node.location.city !== 'undefined' &&
            cinema.node.location.city !== null &&
            typeof tempCitiesObj[cinema.node.location.city] === 'undefined'
          ) {
            tempCitiesObj[cinema.node.location.city] = true;
            tempCities.push(cinema.node.location.city);
          }
        });

      tempCities.sort();

      setCities(tempCities);
    };

    makeCall(null);
  };

  const findCinemas = (request) => {
    props.setIsLoading(true);

    const startTime = performance.now();
    const tempCinemas = [];

    let order = '';
    let distance = '';
    let searchTerm = '';

    if (request && request.search) {
      searchTerm = `search: "${request.search}"`;
      order = '[ALPHABETICAL]';
    }

    if (request && request.location) {
      searchTerm = `
                location: {
                    lat:${request.location.lat},
                    lon:${request.location.lon}
                },
                radius: ${GraphQuerySettings.radius}
            `;
      order = '[CLOSEST, ALPHABETICAL]';
      distance = `
                distance (
                    from: {
                        lat:${request.location.lat},
                        lon:${request.location.lon}
                    },
                    unit: "mi"
                )
            `;
    }

    const makeCall = (endCursor) => {
      let after = '';

      if (typeof endCursor !== 'undefined' && endCursor !== null) {
        after = `after: "${endCursor}",`;
      }

      const query = `
                query {
                    theaterList (
                      affiliation: {
                            activity: THEATER_TRADE_GROUP,
                            companyId: "${GraphQuerySettings.id}"
                        },
                        ${after}
                        countries: [UNITED_KINGDOM],
                        first: ${GraphQuerySettings.limit},
                        order: ${order},
                        ${searchTerm}
                    ) {
                        edges {
                            node {
                                id
                                name
                                url
                                location {
                                    address
                                    city
                                    country
                                    region
                                    zip
                                }
                                coordinates {
                                    latitude
                                    longitude
                                    ${distance}
                                }
                                showtimesDates (
                                    country: [UNITED_KINGDOM]
                                    to: "${
                                      tempDate.toISOString().split('T')[0]
                                    }"
                                )
                            }
                        }
                        pageInfo {
                            hasNextPage
                            endCursor
                        }
                    }
                }
            `;

      GraphQuery(query, 'theaterList')
        .then((r) => {
          if (r.data.theaterList.edges.length === 0) {
            throw Error();
          }

          Array.prototype.push.apply(tempCinemas, r.data.theaterList.edges);

          if (r.data.theaterList.pageInfo.hasNextPage) {
            makeCall(r.data.theaterList.pageInfo.endCursor);
          } else {
            completeCall();
          }
        })
        .catch(() => {
          completeCall();
        });
    };

    const completeCall = () => {
      const results = tempCinemas.filter((item) => {
        return item.node.showtimesDates.length > 0;
      });

      const endTime = performance.now();
      let timeout = 1;

      if (startTime && endTime - startTime < 1000) {
        timeout = 1000 - (endTime - startTime);
      }

      setTimeout(() => {
        props.setIsLoading(false);
        props.setNoResults(results.length > 0 ? false : true);
        props.setResults(results);
      }, timeout);
    };

    makeCall(null);
  };

  const handleSearch = () => {
    if (
      props.isLoading ||
      typeof searchValue === 'undefined' ||
      searchValue === null ||
      searchValue === ''
    ) {
      return;
    }
    setResultName('');
    if (hasGeoLocation && searchValue === useMyLocationLabel) {
      window.navigator.geolocation.getCurrentPosition(
        (position) => {
          findCinemas({
            location: {
              lat: position.coords.latitude,
              lon: position.coords.longitude,
            },
          });
        },
        () => {
          setSearchValue('');
          setHasGeoLocationError(true);
          setHasGeoLocation(false);
        }
      );
      setResultName(searchValue);
    } else if (!/[0-9]/.test(searchValue)) {
      findCinemas({
        search: searchValue,
      });
      setResultName(searchValue);
    } else {
      props.setIsLoading(true);

      let data = {
        searchValue,
      };

      fetch('/api/locationsearch', {
        method: `POST`,
        headers: {
          'content-type': 'application/json',
        },
        body: JSON.stringify(data),
      })
        .then((res) => res.json())
        .then(
          (result) => {
            if (
              result.Items[0] &&
              result.Items[0].Latitude &&
              result.Items[0].Longitude
            ) {
              findCinemas({
                location: {
                  lat: result.Items[0].Latitude,
                  lon: result.Items[0].Longitude,
                },
              });
            } else {
              props.setNoResults(true);
              props.setIsLoading(false);
            }
            setResultName(searchValue);
          },
          // Note: it's important to handle errors here
          // instead of a catch() block so that we don't swallow
          // exceptions from actual bugs in components.
          (error) => {
            console.log(error);
          }
        );
    }
  };

  const handleSearchChange = (evt) => {
    if (
      evt &&
      evt.nativeEvent &&
      (typeof evt.nativeEvent.inputType === 'undefined' ||
        evt.nativeEvent.inputType === null ||
        evt.nativeEvent.inputType === 'insertReplacementText')
    ) {
      setIsDatalist(true);
    }
    setSearchValue(evt.target.value);
  };

  const handleSearchKeyUp = (evt) => {
    if (evt.keyCode === 13) {
      handleSearch();
    }

    props.setNoResults(false);
  };

  const handlePosition = () => {
    setSearchValue(useMyLocationLabel);
  };

  React.useEffect(() => {
    getLocations();

    if (checkGeoLocation === false) {
      setCheckGeoLocation(true);
      if (
        typeof window !== 'undefined' &&
        window !== null &&
        typeof window.navigator !== 'undefined' &&
        window.navigator !== null &&
        typeof window.navigator.geolocation !== 'undefined' &&
        window.navigator.geolocation !== null
      ) {
        setHasGeoLocation(true);
      } else {
        setHasGeoLocation(false);
      }
    }
  });

  React.useEffect(() => {
    if (searchValue === useMyLocationLabel) {
      handleSearch();
    } else if (isDatalist) {
      setIsDatalist(false);
      handleSearch();
    }
  }, [searchValue]);

  return (
    <div className="cinema-finder__search">
      <button
        type="button"
        className="cinema-finder__search__location"
        onClick={handlePosition}
      >
        <span className="cinema-finder__search__location__text">
          Use my location
        </span>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          height="24"
          viewBox="0 0 24 24"
          width="24"
          className="cinema-finder__search__location__icon"
          aria-hidden="true"
        >
          <path
            fill="currentColor"
            d="M12 8c-2.21 0-4 1.79-4 4s1.79 4 4 4 4-1.79 4-4-1.79-4-4-4zm8.94 3c-.46-4.17-3.77-7.48-7.94-7.94V1h-2v2.06C6.83 3.52 3.52 6.83 3.06 11H1v2h2.06c.46 4.17 3.77 7.48 7.94 7.94V23h2v-2.06c4.17-.46 7.48-3.77 7.94-7.94H23v-2h-2.06zM12 19c-3.87 0-7-3.13-7-7s3.13-7 7-7 7 3.13 7 7-3.13 7-7 7z"
          />
        </svg>
      </button>

      <React.Fragment>
        <label className="cinema-finder__search__field">
          <input
            aria-label={props.locationFieldLabel}
            className="cinema-finder__search__field__input"
            list="location-list"
            placeholder={props.locationFieldLabel}
            onChange={handleSearchChange}
            onKeyUp={handleSearchKeyUp}
            value={searchValue}
          />
          <button
            aria-label={props.searchButtonLabel}
            className="cinema-finder__search__field__arrow"
            type="button"
            onClick={handleSearch}
          >
            <svg
              aria-hidden="true"
              className="cinema-finder__search__field__arrow__icon"
              focusable="false"
              viewBox="0 0 24 24"
            >
              <path d="M15.88 9.29L12 13.17 8.12 9.29c-.39-.39-1.02-.39-1.41 0-.39.39-.39 1.02 0 1.41l4.59 4.59c.39.39 1.02.39 1.41 0l4.59-4.59c.39-.39.39-1.02 0-1.41-.39-.38-1.03-.39-1.42 0z" />
            </svg>
          </button>
        </label>
        {resultName && (
          <div className="cinema-finder__results__name">
            <p>
              {props.resultsText} {resultName}
            </p>
          </div>
        )}
        <datalist id="location-list">
          {cities.length > 0 &&
            cities.map((item, index) => <option key={index} value={item} />)}
        </datalist>
      </React.Fragment>
    </div>
  );
};
